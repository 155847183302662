import { MapElementDto } from '@cartken/map-types';
import { InteractiveMode } from './interactive-mode';
import { Color } from './types';
import { DeleteMode } from '../modes/delete-mode';
import { PickingInfo } from '@deck.gl/core/typed';
import { ReachableEdgesMode } from '../modes/reachable-edges-mode';
import { isEdgeBlocked } from './utils';

export enum LayerName {
  COLOR = 'color',
  STEEPNESS = 'steepness',
  SEMANTIC = 'semantic',
  DIFF = 'diff',
}

export interface SlippyTilesStyle {
  opacity: number;
  zIndex: number;
}

export type SlippyTilesStyleMap = { [P in LayerName]: SlippyTilesStyle };

export const slippyTilesStyles: SlippyTilesStyleMap = {
  color: { opacity: 0.0, zIndex: 1 },
  steepness: { opacity: 1.0, zIndex: 2 },
  semantic: { opacity: 0.2, zIndex: 3 },
  diff: { opacity: 0.0, zIndex: 4 },
};

export function getLineColor(
  m: MapElementDto,
  isSelected: boolean,
  editMode?: InteractiveMode,
): Color {
  if (
    editMode instanceof ReachableEdgesMode &&
    (m.elementType === 'RobotQueueEdge' || m.elementType === 'RobotEdge')
  ) {
    return [255, 0, 0, 255];
  }
  if (isSelected) {
    return [0, 200, 200, 0xff];
  }
  if (m.elementType === 'Node') {
    return [0, 0xff, 0, 0xff];
  }
  if (m.elementType === 'OperationRegion') {
    return [0xff, 0, 0xff, 0xff];
  }

  let color: Color = [0xdd, 0xdd, 0, 0xff];

  if (
    m.properties &&
    'allowUnsupervisedAutonomy' in m.properties &&
    m.properties.allowUnsupervisedAutonomy
  ) {
    color = [0, 0, 255, 0.2 * 255];
  }

  if (m.elementType === 'RobotQueueEdge') {
    color = [0xdd, 0x33, 0, 0xff];
  }
  if (m.elementType === 'RoadEdge') {
    color = [0xff, 0, 0xff, 0xff];
  }
  if (m.elementType === 'CachedRoadEdge') {
    color = [0xff, 0xbb, 0xff, 0xff];
  }
  if (m.properties && 'crossing' in m.properties && m.properties.crossing) {
    color = [0xff, 0x66, 0, 0xff];
  }
  if (isEdgeBlocked(m)) {
    color = [0xb7, 0, 0, 0xff];
  }

  return color;
}

export function getCorridorColor(m: MapElementDto): Color {
  if (
    m.properties &&
    'allowUnsupervisedAutonomy' in m.properties &&
    m.properties.allowUnsupervisedAutonomy
  ) {
    return [0, 0, 255, 0.2 * 255];
  }
  return [0, 0, 0, 0.2 * 255];
}

export function getHighlightColor(
  pickingInfo: PickingInfo,
  editMode: InteractiveMode,
): Color {
  if (editMode instanceof DeleteMode) {
    return [255, 0, 0, 255];
  }
  return [0, 255, 255, 255];
}

export function getIcon(m: MapElementDto) {
  switch (m.elementType) {
    case 'AprilTag':
      return {
        id: m.elementType,
        width: 20,
        height: 20,
        url: 'assets/april-tag.svg',
      };
    case 'HandoverLocation':
      return {
        id: m.elementType,
        width: 20,
        height: 20,
        url: 'assets/handover-location.svg',
      };
    case 'Mutex':
      return {
        id: m.elementType,
        width: 20,
        height: 20,
        url: 'assets/mutex.svg',
      };
    case 'Infrastructure':
      return {
        id: m.elementType,
        width: 20,
        height: 20,
        url: 'assets/infra.svg',
      };
    case 'TrafficLight':
      return {
        id: m.elementType,
        width: 20,
        height: 20,
        url: 'assets/traffic-light.svg',
      };
    default:
      return {
        id: m.elementType,
        width: 0,
        height: 0,
        url: 'assets/april-tag.svg',
      };
  }
}
